<script setup lang="ts">

  import type { FormWidgetData } from "../../../widgetDataTs.js";
  import type { ContactFormSettingsKey } from "../../form-settings/form-experience/form-settings-keys.js";
  import ContactForm from "../form-element/contact-form-base.vue";
  import { useFormServices } from "../../composables/use-form-services.js";
  import { computed } from "vue";
  import { type Nil } from "@mcwd/typescript-type-guards";
  import { TranslationNamespaceEnum } from "../../locale/settings/translation-namespaces.js";
  import { useTranslation } from "i18next-vue";
  import { ContactFormPostKeysEnum } from "../../locale/translation-keys/all-keys.js";
  import { getCustomFormText } from "../../composables/use-custom-data-values.js";

  const props = defineProps<{
    formSettingsKey: ContactFormSettingsKey;
    widgetData: FormWidgetData<"ContactForm">;
    customData: Record<string, any>;
    isInModal?: boolean | Nil;
  }>();
  const { FormState } = useFormServices({ callerLabel: "demo-request" });

  const customFormHeading = computed(() => { return getCustomFormText(props.customData, 'formHeading'); });
  
  const showUnlockedView = computed(() => {
    return FormState.value === "RegularFormComplete";
  });
  const { t } = useTranslation();

</script>

<template>
  <div class="eclipse-demo-request-form">
    <template v-if="!showUnlockedView">
      <ContactForm :form-settings-key="formSettingsKey" :widget-data="widgetData" :custom-data="customData">
        <template #heading>
          <h5 class="supporting-copy form-instructions-heading">
            {{ customFormHeading ?? "Let's get your demo scheduled!" }}
          </h5>
        </template>
      </ContactForm>
    </template>
    <template v-else>
      <!--thank you message-->
      <div class="form-completion-message after-complete">
        <p>
          <img class="email" src="https://static.mastercontrol.com/assets/persist/images/mcui-resources-sent.png" alt="email sent">
        </p>
        <h2 class="complete">{{ t(ContactFormPostKeysEnum.demoRequestMessage, {ns: TranslationNamespaceEnum.contactPostSubmit}) }}</h2>
      </div>
    </template>
  </div>
</template>